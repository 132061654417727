import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql, navigate } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import useSiteSettings from '~utils/useSiteSettings'
import Section from '~components/Section'
import Image from '~components/Image'
import RichText from '~components/RichText'
import Button from '~components/Button'
import { ChevronDown } from '~components/Svg'
import { mobile, HeaderImageDesk, HeaderImageMobile } from '~styles/global'
import useBreakpoint from '~utils/useBreakpoint'
import resolveLink from '~utils/resolveLink'
import RenderModules from '~components/RenderModules'

const IndexPage = ({ data }) => {
	const { page } = data
	const [selectedQuery, setSelectedQuery] = useState(undefined)
	const [querySelecterOpen, setQuerySelecterOpen] = useState(undefined)
	const { isMobile } = useBreakpoint()

	const setAndClose = i => {
		if ( isMobile ) {
			navigate(resolveLink(page?.queries[i]?.link?.document))
		} else {
			setSelectedQuery(i)
			setQuerySelecterOpen(false)
		}
	}

	return (
		<>	
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<Header>
				<HeaderImageDesk image={page?.headerImage} cover hideMobile={page.headerImageMobile}/>
				<HeaderImageMobile image={page?.headerImageMobile} cover/>
				<HeaderContent>
					<HeaderContentInner>
						<Heading>{page.heading}</Heading>
						<Intro><RichText content={page.introduction}/></Intro>
						<Query>
							<QuerySelecterContainer>
								<QuerySelector open={querySelecterOpen} onClick={() => setQuerySelecterOpen(!querySelecterOpen)}>
									{selectedQuery === undefined ? 
										<Placeholder className='h3' >
											{page?.queryPlaceholder}							
										</Placeholder>
										:
										<SelectedQuery >
											{page?.queries[selectedQuery]?.text}									
										</SelectedQuery>
									}	
									<Toggle >
										<Chevron open={querySelecterOpen}/>
									</Toggle>
								</QuerySelector>
								<QueryOptions open={querySelecterOpen}>
									{page?.queries?.map((query, i) => (
										<QueryOption key={query.text} onClick={() => setAndClose(i)}>
											{query.text}
										</QueryOption>
									))}
								</QueryOptions>
							</QuerySelecterContainer>
							<GoButton black large link={selectedQuery !== undefined ? page?.queries[selectedQuery]?.link : {}}>Go</GoButton>
						</Query>
					</HeaderContentInner>
				</HeaderContent>
			</Header>
			<RenderModules modules={page.modules}/>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const Header = styled.div`
	position: relative;
`
const HeaderContent = styled(Section)`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	>div{
		height: 100%;
	}
	${mobile}{
		position: relative;
	}
`
const HeaderContentInner = styled.div`
	align-self: center;
	grid-column: 2/7;
	max-width: 473px;
	${mobile}{
		grid-column: span 12;
		margin-top: 24px;
	}
`
const Heading = styled.h1`
	margin-bottom: 48px;
`
const Intro = styled.h3`
	margin-bottom: 54px;
	font-weight: 300;
	${mobile}{
		font-size: 16px;
		line-height: 21px;
	}
`
const Query = styled.div`
	display: flex;
`
const QuerySelecterContainer = styled.div`
	position: relative;
	flex: 1;
`
const QuerySelector = styled.button`
	height: 60px;
	border-radius: ${ props => props.open ? '30px 30px 0 0' : '30px 30px 30px 30px'};
	border: 1px solid var(--black);
	display: flex;
	align-items: center;
	min-width: 351px;
	width: 100%;
	background-color: ${ props => props.open ? 'var(--white)' : 'transparent'};
	transition: border-radius 0.25s, background-color 0.25s;
	${mobile}{
		min-width: none;
	}
`
const Placeholder = styled.div`
	font-weight: 300;
	padding: 0 25px;
	flex: 1;
	text-align: center;
	overflow: elipsis;
	white-space: nowrap;
`
const SelectedQuery = styled.div`
	font-weight: 300;
	padding: 0 25px;
	flex: 1;
	text-align: center;
	overflow: elipsis;
	white-space: nowrap;
`
const GoButton = styled(Button)`
	min-width: 109px;
	margin-left: 10px;
	${mobile}{
		display: none;
	}
`
const QueryOptions = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	opacity: ${ props => props.open ? '1' : '0'};
	pointer-events: ${ props => props.open ? 'all' : 'none'};
	border: 1px solid var(--black);
	border-top: none;
	border-radius: 0 0 30px 30px;
	background-color: ${ props => props.open ? 'var(--white)' : 'transparent'};
	overflow: hidden;
	transition: opacity 0.3s, background-color 0.3s;
	transition-delay: ${ props => props.open ? '0.2s' : '0'};
	z-index: 2;
`
const QueryOption = styled.button`
	height: 60px;
	border-bottom: 1px solid var(--black);
	width: 100%;
	font-weight: 300;
	:hover{
		background-color: var(--grey);
	}
	:last-of-type{
		border-bottom: none;
	}
`
const Toggle = styled.div`
	border-left: 1px solid;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
`
const Chevron = styled(ChevronDown)`
	width: 15px;
	transform: ${ props => props.open ? 'rotate(180deg)' : 'rotate(0deg)'};
	transition: transform 0.25s;
`
export const query = graphql`
  query HomeQuery {
    page: sanityHomePage {
      title
			seo{
				...seo
			}
			title
			heading
			introduction: _rawIntroduction(resolveReferences: {maxDepth: 5})
			queryPlaceholder
			queries{
				text
				link {
					...link
				}
			}
			headerImage {
				...imageWithAlt
			}
			headerImageMobile{
				...imageWithAlt
			}
			modules {
				... on SanityAccordion {
					...accordion
				}
				... on SanityAccreditedBroker {
					_key
					_type
				}
				... on SanityArticleCollection {
					...articleCollection
				}
				... on SanityCalculatorLinks {
					...calculatorLinks
				}
				... on SanityContactForm {
					...contactForm
				}
				... on SanityHeadingTextModule {
					...headingText
				}
				... on SanityImageWithCaption {
					_key
					_type
					...imageWithCaption
				}
				... on SanityLogoGrid {
					...logoGrid
				}
				... on SanityTestimonials {
					...testimonials
				}
			}
    }
  }
`

IndexPage.propTypes = {
	data: PropTypes.object,
}

export default IndexPage
